.mat-tab-group.full-height-tab-group {
  height: 100%;

  .mat-tab-body-wrapper {
    flex: 1;
  }
}

.cdk-column-logo,
.cdk-column-avatar,
.cdk-column-photo {
  max-width: 40px !important;
  min-width: 40px !important;
  width: 40px !important;
  box-sizing: content-box !important;
  padding-right: 0 !important;
}

.mat-ink-bar {
  height: 4px !important;
}

.mdc-tab {
  letter-spacing: 0.02em !important;
}
