.icon-text-button {
  border-radius: 20px;
  border-width: 1.5px;
  box-shadow: none;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 15px;
  text-align: left;
  cursor: pointer;
  justify-content: center;

  &--has-text {
    flex-direction: column;
    gap: 0;
    padding: 20px 25px;
    justify-content: normal;

    .icon-text-button__Icon {
      margin-bottom: 20px;
    }

    .icon-text-button__Title {
      margin-bottom: 10px;
    }
  }

  &__Icon {
    font-size: 24px;
  }

  &__Title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 20px;
  }

  &__Text {
    margin: 0;
    font-size: 18px;
  }
}

.icon-text-button-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;

  // make into one row on small screens
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}
