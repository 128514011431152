@use '@angular/material' as mat;
@use 'sass:map';

.badge {
  border-radius: 7px;
  text-align: center;
  font-size: 11px;
  padding: 1px 3px;
  min-width: 1.15em;
  display: inline-block;
  line-height: 1.5em;
  font-weight: 500;
}

@mixin tremaze-badge-theme($theme) {
  $accent: map.get($theme, accent);
  $primary: map.get($theme, primary);
  $type: mat.get-theme-type($theme);

  .badge {
    background-color: mat.m2-get-color-from-palette($accent);
    color: mat.m2-get-color-from-palette($accent, default-contrast);

    &--primary {
      background-color: mat.m2-get-color-from-palette($primary);
      color: mat.m2-get-color-from-palette($primary, default-contrast);
    }

    &--grey {
      background-color: if($type == dark, #333, #f1f1f1);
      color: if($type == dark, #f1f1f1, #333);
    }
  }
}
