@use '@angular/material' as mat;
@use 'sass:map';

@mixin tremaze-button-theme($theme) {
  $accent: map.get($theme, accent);
  $accent-color: mat.m2-get-color-from-palette($accent);
  button.active {
    color: $accent-color !important;
  }

  button.pulse {
    box-shadow: 0 0 0 $accent-color;
    animation: pulse 2s infinite;

    &:hover {
      animation: none;
    }
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

.mat-mini-fab .mat-button-wrapper {
  line-height: 21px !important;
}
