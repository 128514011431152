@use 'sass:map';
@use '@angular/material' as mat;
@use 'card-globals';

@mixin color($theme) {
  // Retrieve the background color palette from the theme
  $background-palette: map.get($theme, background);
  $foreground-palette: map.get($theme, foreground);
  $type: mat.get-theme-type($theme);

  // Extract the card color from the background palette
  // If there's a specific "card" key in the background palette, use it
  // Otherwise, fallback to the theme's default background color
  $card-background-color: if(
    map.has-key($background-palette, card),
    map.get($background-palette, card),
    map.get($background-palette, background)
  );

  $card-shadow-color: if(
    map.has-key($foreground-palette, elevation),
    map.get($foreground-palette, elevation),
    map.get($foreground-palette, foreground)
  );

  $foreground-color: if(
    map.has-key($foreground-palette, text),
    map.get($foreground-palette, text),
    map.get($foreground-palette, foreground)
  );

  $border-color: if($type == dark, #555555, #ececec);

  .tremaze-card {
    background-color: $card-background-color;
    color: $foreground-color;

    &--floating {
      box-shadow: rgba(128, 128, 128, 0.53) 0 0 25px;
    }

    &--outlined {
      border: 2px solid $border-color;
      box-shadow:
        0 4px 10px 0 rgba(0, 0, 0, 0.05),
        0 18px 18px 0 rgba(0, 0, 0, 0.05),
        0 40px 24px 0 rgba(0, 0, 0, 0.03),
        0 70px 28px 0 rgba(0, 0, 0, 0),
        0 110px 31px 0 rgba(0, 0, 0, 0);
    }
  }
}

@mixin typography($theme) {
  .tremaze-card {
    &__Title {
      font-family: var(--mat-card-title-text-font);
      line-height: var(--mat-card-title-text-line-height);
      font-size: var(--mat-card-title-text-size);
      letter-spacing: var(--mat-card-title-text-tracking);
      font-weight: var(--mat-card-title-text-weight);
    }

    &__Subtitle {
      color: var(--mat-card-subtitle-text-color);
      font-family: var(--mat-card-subtitle-text-font);
      line-height: var(--mat-card-subtitle-text-line-height);
      font-size: var(--mat-card-subtitle-text-size);
      letter-spacing: var(--mat-card-subtitle-text-tracking);
      font-weight: var(--mat-card-subtitle-text-weight);
    }
  }
}

@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }

  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}
