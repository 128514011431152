@use 'sass:map';
@use '../../../../../../../../../node_modules/@angular/material/index' as mat;

@mixin palette($theme, $paletteName) {
  $type: mat.get-theme-type($theme);
  $item-color: if($type == dark, #2f2f2f, #f1f1f1);
  $background-color: mat.get-theme-color($theme, background, card);
  $item-text-color: if($type == dark, #ffffff, #000000);
  $item-hover-color: if($type == dark, #1a1a1a, #e1e1e1);

  $selected-hue: if($type == dark, 500, 100);
  $selected-hover-hue: if($type == dark, 600, 200);
  $selected-contrast-hue: if($type == dark, 500-contrast, 100-contrast);
  $drop-zone-hue: if($type == dark, 400, 400);
  $drop-zone-hover-hue: if($type == dark, 300, 200);
  $drop-zone-hover-contrast-hue: if($type == dark, 300-contrast, 300-contrast);

  $palette: map.get($theme, primary);
  $accent-palette: map.get($theme, accent);

  $item-selected-color: mat.get-theme-color(
                  $theme,
                  $paletteName,
                  $selected-hue
  );
  $item-selected-hover-color: mat.get-theme-color(
                  $theme,
                  $paletteName,
                  $selected-hover-hue
  );
  $item-selected-text-color: mat.get-theme-color(
                  $theme,
                  $paletteName,
                  $selected-contrast-hue
  );

  $drag-box-color: mat.m2-get-color-from-palette(
                  $palette,
                  if($type == dark, 500-contrast, 100-contrast),
                  0.15
  );
  $drag-boc-border-color: mat.m2-get-color-from-palette(
                  $palette,
                  if($type == dark, 500-contrast, 100-contrast)
  );

  $drop-zone-color: mat.get-theme-color($theme, $paletteName, $drop-zone-hue);
  $drop-zone-hover-color: mat.get-theme-color(
                  $theme,
                  $paletteName,
                  $drop-zone-hover-hue
  );
  $drop-zone-hover-contrast-color: mat.get-theme-color(
                  $theme,
                  $paletteName,
                  $drop-zone-hover-contrast-hue
  );

  $elevation-color: mat.get-theme-color($theme, foreground, elevation);

  .select-zone-selection-box {
    background: $drag-box-color;
    border: 1px solid $drag-boc-border-color;
  }

  .drag-zone {
    &--Dragging {
      .drop-zone:not(.drop-zone--Disabled) {
        outline: 2px dashed $drop-zone-color;

        &:hover {
          background: $drop-zone-hover-color !important;
          color: $drop-zone-hover-contrast-color !important;
        }
      }
    }
  }

  .file-drop-in-drop-zone {

    &--DraggingOver:not(.folder-view-folders-and-files__Body) {
      background: $drop-zone-hover-color !important;
      color: $drop-zone-hover-contrast-color !important;
    }

    &--Dragging:not(.folder-view-folders-and-files__Files-Wrapper):not(&.file-drop-in-drop-zone--Disabled) {
      outline: 2px dashed $drop-zone-color;
    }

    &--Dragging:not(&.file-drop-in-drop-zone--Disabled) {
      .folder-view-folders-and-files__Files-Wrapper__Drop-Zone {
        outline: 2px dashed $drop-zone-color;

      }
    }
  }


  .folder-view-actions-row {
    background: transparent;
  }

  .folder-view > div,
  .folder-view-list-layout-list {
    background: $background-color;
  }

  .folder-view-grid-layout-item {
    background: $item-color;
    color: $item-text-color;
    transition: background 0.1s;
    overflow: visible;

    &:hover:not(&--Disabled):not(&--Selected) {
      background: $item-hover-color;
    }

    &--Selected {
      background: $item-selected-color;
      color: $item-selected-text-color;

      &:hover {
        background: $item-selected-hover-color;
      }
    }
  }
}

@mixin theme($theme) {
  @include palette($theme, primary);
}
