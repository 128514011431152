.tremaze-card {
  overflow: hidden;

  &__Header {
    &__Text {
      display: flex;
      flex-direction: column;
      flex: 1;
      min-width: 0;
    }
  }

  &__Title {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }

  &__Content {
    display: block;
  }

  &__Avatar {
    margin-right: 10px;
  }

  &__Footer {
    display: flex;

    &--align-start {
      justify-content: flex-start;
    }

    &--align-end {
      justify-content: flex-end;
    }

    &--align-center {
      justify-content: center;
    }

    &--align-stretch {
      justify-content: stretch;
    }
  }
}
