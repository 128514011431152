@use '@angular/material' as mat;
@use 'sass:map';
@use 'base-theme';
@use '../styles/helpers/helpers.typography';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

$typography: helpers.define-default-mat-typography('Arial');

$md-primary: (
  50: #e1f1f1,
  100: #b3dcdc,
  200: #81c5c5,
  300: #4faead,
  400: #299c9c,
  500: #038b8a,
  600: #038382,
  700: #038b8a,
  800: #026e6d,
  900: #015b5a,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
  ),
);

$md-accent: (
  50: #fdf2e1,
  100: #fbddb4,
  200: #f8c884,
  300: #f6b153,
  400: #f6a12e,
  500: #f49112,
  600: #f08611,
  700: #ea760f,
  800: #e3670d,
  900: #da4d0a,
  A100: #fbddb4,
  A200: #f8c884,
  A400: #f6a12e,
  A700: #ea760f,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$material-theme-overrides: (
  'color': (
    'foreground': (
      'text': #00273c,
    ),
    'background': (
      'background': #f5f4f0,
    ),
  ),
);

@include base-theme.theme-base-mixin(
  $md-primary,
  $md-accent,
  mat.$m2-red-palette,
  $typography,
  $material-theme-overrides
);

body:not(.dark-theme) {
  .main-drawer-container__content__toolbar,
  tremaze-folder-view .page__head {
    background: linear-gradient(to right, #5fb98b, #1e5674) !important;
  }
}
