gridster {
  background: transparent !important;

  gridster-item {
    background: transparent !important;
    margin: 10px;
    border-radius: 5px;

    * > :last-child & > :first-child {
      height: 100%;
    }

    .mat-card-header {
      display: flex;
      justify-content: space-between;
    }

    mat-card {
      box-shadow: none !important;
      position: static !important;
    }

    .table-wrapper__container,
    .table-wrapper__container > mat-card,
    .table-wrapper__container__filter {
      padding-top: 0 !important;
    }

    .table-wrapper__container > mat-card {
      padding: 0 !important;
      margin: 0 !important;
      border-radius: 0 !important;
    }
  }
}

.grid-item-content > *:not(.grid-item-content__expand-button) {
  height: 100%;
}
