@use 'sass:map';
@use '@angular/material' as mat;

.innerHTML {
  ul {
    list-style-type: revert;
    padding: revert;
    margin: revert;
  }
}

@mixin include-textblock-styles($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $palette: map.get($color-config, foreground);

  .innerHTML {
    color: mat.m2-get-color-from-palette($palette, text);
  }
}
