@use '@angular/material' as mat;
@use 'sass:map';

@mixin tremaze-hint-theme($theme) {
  $accent: map.get($theme, accent);
  $accent-color: mat.m2-get-color-from-palette($accent);
  $primary: map.get($theme, primary);
  $primary-color: mat.m2-get-color-from-palette($primary);
  $warn: map.get($theme, warn);
  $warn-color: mat.m2-get-color-from-palette($warn);
  $background: map.get($theme, background);
  $background-color: mat.m2-get-color-from-palette($background);

  .hint {
    $background-alpha: 0.075;

    &--primary {
      $hint-color: $primary-color;
      background-color: rgba($hint-color, $background-alpha);
      border-color: $hint-color !important;

      > p {
        border-color: $hint-color !important;
      }
    }

    &--accent {
      $hint-color: $accent-color;
      background-color: rgba($hint-color, $background-alpha);
      border-color: $hint-color !important;

      > p {
        border-color: $hint-color !important;
      }
    }

    &--warn {
      $hint-color: $warn-color;
      background-color: rgba($hint-color, $background-alpha);
      border-color: $hint-color !important;

      > p {
        border-color: $hint-color !important;
      }
    }
  }
}
