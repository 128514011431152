@use 'sass:map';
@use '@angular/material' as mat;

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}


table:not(.default-table) tbody tr:hover {
  background-color: rgba(136, 136, 136, 0.1) !important;
}

.dark-theme {
  .default-table thead {
    border-bottom: 1px solid #ffffff24;
  }
}

.default-table {
  border-collapse: collapse;

  thead {
    th {
      font-size: 1.15rem;
      font-weight: bold;
    }
  }

  td {
    padding: 12px 5px;
    vertical-align: top;
  }

  th {
    text-align: left;
  }

  tr {
    transition: background-color 0.2s ease-in-out;
  }
}

.table-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__container {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding-top: 15px;

    &__table-wrapper {
      overflow: auto;
      flex: 1;

      table {
        margin-bottom: 30px;
      }

      &__reload {
        margin-left: auto;
      }
    }

    &__filter {
      display: flex;
      padding: 10px 20px 0 10px;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;

      &__reset {
        margin-right: 20px;
        cursor: pointer;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }
  }
}

@mixin include-table-styles($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $background-palette: map.get($color-config, background);
  $background-color: mat.m2-get-color-from-palette($background-palette, card);

  .default-table {
    thead {
      background-color: $background-color;
      border-bottom: 20px solid transparent;
    }

    $hue: mat.m2-get-color-from-palette($primary-palette, 50);
    $odd-color: rgba($hue, 0.5);
    $hover-odd-color: rgba($hue, 0.8);
    $hover-color: rgba($hue, 0.3);

    tbody > tr:not(&__nostyle) {
      &:hover {
        background-color: $hover-color !important;
      }
    }

    &:not(.even) tbody tr:nth-child(odd):not(&__nostyle),
    &.even tbody tr:nth-child(even):not(&__nostyle) {
      background-color: $odd-color;

      &:hover {
        background-color: $hover-odd-color !important;
      }
    }
  }
}
