.tour-popover {
  &--Single-step {
    .prev,
    .progress {
      visibility: hidden;
    }
  }

  &--with-padding {
    .touranchor--is-active {
      padding: 10px;
      margin: -10px;
    }
  }
}

.tour-step {
  mat-card {
    max-width: unset !important;
  }
}
