.center-children-vertically {
  display: flex;
  align-items: center;
}

.center-children-horizontally {
  display: flex;
  justify-content: center;
}

.center-children {
  @extend .center-children-horizontally;
  @extend .center-children-vertically;
}

.center-text {
  text-align: center;
}

.d-flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

mat-accordion mat-expansion-panel {
  mat-expansion-panel-header {
    height: 40px; // height may be different for you
  }
  .mat-expansion-panel-content {
    height: 0;
  }
  &.mat-expanded {
    mat-expansion-panel-header {
      height: 64px; // height may be different for you
    }
    .mat-expansion-panel-content {
      height: auto;
    }
  }
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.spacer {
  flex: 1 1 0;
}

.gridster-nodrag {
}

.d-none {
  display: none !important;
}

.green-color {
  color: green;
}

.red-color {
  color: red;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
