.dark-theme input[type='time']::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.mat-input-element[type='date']::after,
.mat-input-element[type='datetime']::after,
.mat-input-element[type='datetime-local']::after,
.mat-input-element[type='month']::after,
.mat-input-element[type='week']::after,
.mat-input-element[type='time']::after {
  content: none !important;
}
