@use '@angular/material' as mat;
@use 'sass:map';
@use 'elements.blockquote';

/*------------------------------------*\
    #PAGE
\*------------------------------------*/

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

.cdk-drag-handle:not(.disabled) {
  cursor: grab;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging .cdk-drag:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-placeholder {
  opacity: 0;
}

hr {
  margin: 0;
}

::ng-deep
  .ng-animating
  div
  mat-accordion
  mat-expansion-panel
  mat-expansion-panel-header {
  height: 48px;
}

::ng-deep
  .ng-animating
  div
  mat-accordion
  mat-expansion-panel
  div.mat-expansion-panel-content {
  height: 0px;
  visibility: hidden;
}

.fade-animation-outlet-wrapper {
  position: relative;
  flex: 1;
  overflow: hidden !important;

  > router-outlet ~ * {
    position: absolute;
    width: 100%;
    height: 100%;

    > * {
      height: 100%;
    }
  }
}

table {
  width: 100%;
}

.page {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;

  &__head,
  &__Head {
    padding: 20px 20px;
    z-index: 2;
    display: flex;
    align-items: center;

    &:not(&--flat) {
      box-shadow:
        0 3px 1px -2px rgb(0 0 0 / 20%),
        0 2px 2px 0 rgb(0 0 0 / 14%),
        0 1px 5px 0 rgb(0 0 0 / 12%);
    }

    &--title-center {
      justify-content: center;
      text-align: center;
    }
  }

  &__title,
  &__Title {
    margin: 0;
  }

  &__body,
  &__Body {
    flex: 1;
    overflow: auto;

    &--no-scroll {
      overflow: hidden;
    }

    &__empty {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      mat-icon {
        font-size: 35px;
      }

      * {
        text-align: center;
      }
    }
  }

  &__actions,
  &__Actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 20px;
    border-top: 1px solid rgba(211, 211, 211, 0.25);

    > *:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.center-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

tremaze-main {
  tremaze-main-nav-header ~ mat-nav-list {
    .mdc-list-item-content {
      padding: 0 !important;

      > a {
        padding: 0 16px;
      }
    }
  }
}
